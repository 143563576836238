@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&&display=swap');

html {
    font-size: 16px;
}

* {
    box-sizing: border-box;
    line-height: 2;
}
